<template>
	<a-space direction="vertical">
		<a-card title="查询条件">
			<a-form-model @submit.prevent="onSearch" layout="horizontal" v-bind="{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }">
				<a-row>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="手机号码"><a-input v-model="query.mobile" placeholder="请输入手机号码"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="类型">
							<a-select style="width: 100%" v-model="query.type" placeholder="请选择类型">
								<a-select-option value="">全部</a-select-option>
								<a-select-option :value="1">微信</a-select-option>
								<a-select-option :value="2">支付宝</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="状态">
							<a-select style="width: 100%" v-model="query.status" placeholder="请选择状态">
								<a-select-option value="">全部</a-select-option>
								<a-select-option :value="0">待审核</a-select-option>
								<a-select-option :value="1">已通过</a-select-option>
								<a-select-option :value="2">被驳回</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="时间"><a-range-picker style="width: 100%" :value="selectedDateRange" @change="onChange" /></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item :wrapper-col="{ span: 16, offset: 6 }">
							<a-space>
								<a-button type="primary" html-type="submit">查询</a-button>
								<a-button @click="onReset">重置</a-button>
							</a-space>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-card>
		<a-card title="提现申请列表">
			<a-space slot="extra"><a-button type="primary" @click="onExport">导出</a-button></a-space>

			<div class="statistics">
				<span>订单总数：{{ lists.total }}</span>
				<span>订单用户：{{ lists.countUser }}</span>
				<span>提现总金额：{{ lists.countMoney }}</span>
			</div>

			<a-table
				rowKey="id"
				:data-source="lists.data"
				:pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }"
				@change="onPageChange"
				:scroll="{ x: 'max-content' }"
			>
				<a-table-column key="id" title="ID" data-index="id" />
				<a-table-column key="mobile" title="用户" data-index="mobile">
					<template slot-scope="text, record">
						{{ record.nickname }}
						<br />
						({{ record.mobile }})
					</template>
				</a-table-column>
				<a-table-column key="userBalance" title="用户余额" data-index="userBalance" />
				<a-table-column key="accountNumber" title="收款信息" data-index="accountNumber">
					<template slot-scope="text, record">
						<!-- <span class="label">备注名称：</span> {{ record.userName }}<br> -->
						<span class="label">收款账号：</span>
						{{ record.accountNumber }}
						<br />
						<span class="label">收款姓名：</span>
						{{ record.userName }}
					</template>
				</a-table-column>
				<a-table-column key="codeUrl" title="收款码" data-index="codeUrl">
					<template slot-scope="codeUrl, record">
						<vue-viewer
							:images="[codeUrl]"
							:toolbarOptions="{
								zoomIn: 4,
								zoomOut: 4,
								oneToOne: 4,
								reset: 4,
								prev: false,
								play: false,
								next: false,
								rotateLeft: 4,
								rotateRight: 4,
								flipHorizontal: 4,
								flipVertical: 4
							}"
						></vue-viewer>
						<!-- <a-avatar shape="square" :size="64" :src="codeUrl" @click="onPreview(codeUrl)" /> -->
					</template>
				</a-table-column>
				<a-table-column key="amount" title="金额" data-index="amount">
					<template slot-scope="text, record">
						{{ record.amount }}
					</template>
				</a-table-column>
				<a-table-column key="typeStr" title="类型" data-index="typeStr" />
				<a-table-column key="status" title="状态" data-index="status">
					<template slot-scope="status, record">
						<a-tag color="orange" v-if="status == 0">{{ record.statusStr }}</a-tag>
						<a-tag color="green" v-else-if="status == 1">{{ record.statusStr }}</a-tag>
						<a-tag color="red" v-else-if="status == 2">{{ record.statusStr }}</a-tag>
						<div v-if="status > 0" style="margin-top: 4px;"> 
							{{ record.mtime }}
						</div>
					</template>
				</a-table-column>
				<!-- <a-table-column key="mtime" title="审核时间" data-index="mtime">
					<template slot-scope="status, record">
						<div v-if="status > 0" style="margin-top: 4px;"> 
							{{ record.mtime }}
						</div>
					</template>
				</a-table-column> -->
				<a-table-column key="ctime" title="创建时间" data-index="ctime" />
				<a-table-column key="action" title="操作" fixed="right">
					<template slot-scope="text, record">
						<a-space v-if="record.status == 0">
							<a-popconfirm title="确认要通过吗？" @confirm="onAudit(record.id, 1)"><a-button size="small" type="primary">通过</a-button></a-popconfirm>
							<a-button size="small" type="danger" @click="onReject(record)">拒绝</a-button>
						</a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-card>

		<a-modal v-model="modal.visible" title="驳回理由" @ok="onAudit(modal.data.id, 2, modal.text)">
			<a-textarea placeholder="请输入驳回理由" :rows="4" v-model="modal.text" />
		</a-modal>
	</a-space>
</template>

<script>
import moment from 'moment';
import VueViewer from 'vue-viewerjs';
const formatter = 'YYYY-MM-DD';
const DEFAULT_QUERY = {
	page: 1,
	size: 10,
	status: undefined,
	type: undefined,
	mobile: undefined,
	beginTime: undefined,
	endTime: undefined
};
export default {
	components: { VueViewer },
	data() {
		return {
			query: Object.assign({}, DEFAULT_QUERY),
			lists: {
				data: [],
				current_page: 1,
				per_page: 10,
				total: 0
			},
			modal: {
				visible: false,
				text: '',
				data: {}
			}
		};
	},
	computed: {
		selectedDateRange() {
			const { beginTime, endTime } = this.query;
			if (beginTime && endTime) {
				return [moment(beginTime, formatter), moment(endTime, formatter)];
			} else {
				return null;
			}
		}
	},
	mounted() {
		this.getWithdraws();
	},
	methods: {
		async getWithdraws() {
			const response = await this.$api.get('/withdrawal_list', {
				params: this.query
			});
			if (response && response.code == 200) {
				this.lists = Object.assign(this.lists, response.data);
			}
		},
		async onPageChange(pagination) {
			this.query.page = pagination.current;
			this.query.size = pagination.pageSize;
			this.getWithdraws();
		},
		async onSearch() {
			this.query.page = 1;
			this.getWithdraws();
		},
		async onReset() {
			this.query = Object.assign({}, DEFAULT_QUERY);
			this.getWithdraws();
		},
		async onChange(o, dateStr) {
			if (dateStr.length === 2) {
				this.query.beginTime = dateStr[0];
				this.query.endTime = dateStr[1];
			} else {
				this.query.beginTime = undefined;
				this.query.endTime = undefined;
			}
		},
		async onReject(record) {
			this.modal.data = record;
			this.modal.text = '';
			this.modal.visible = true;
		},
		async onAudit(id, status, remark = '') {
			var response = await this.$api.post('/withdrawal_examine', {
				id,
				status,
				payDesc: remark
			});
			console.info('response', response);
			if (response && response.code == 200) {
				this.getWithdraws();
				this.modal.visible = false;
				this.$message.success('操作成功!');
			}
		},
		async onExport() {
			this.$store.dispatch('setLoading', true);
			const response = await this.$api.get('/withdrawal_export', {
				params: this.query,
				responseType: 'arraybuffer'
			});
		}
	}
};
</script>
